.cursor-pointer {
    cursor: pointer !important;
}

.border-round-md {
    border-radius: .375rem !important;
}

.border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
}

.mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}

.px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.border-color-500 {
    border-color: #4c63b6 !important;
}

.surface-50 {
    background-color: var(--surface-50) !important;
}

.text-base {
    font-size: 0.8rem !important;
}
.font-medium {
    font-weight: 650 !important;
}
.text-color {
    color: var(--text-color) !important;
}

.text-sm {
    font-size: .875rem !important;
}

.text-color-secondary {
    color: var(--text-color-secondary) !important;
}

.active\:shadow-2:active, .focus\:shadow-2:focus, .hover\:shadow-2:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, .03), 0 0 2px rgba(0, 0, 0, .06), 0 2px 6px rgba(0, 0, 0, .12) !important;
}

.active\:surface-card:active, .focus\:surface-card:focus, .hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
}