.wrapper {
  margin-bottom: 32px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto";
}

.gridContainer {
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.oneColumn {
  grid-template-columns: 1fr;
}

.divider {
  width: 100%;
  margin: 16px 0;
  border-bottom: 2px solid #f7f8f9;
}
