.indicator-container {
  position: relative;
  display: flex;
  border-top: 6px solid #183462;
}

.indicator-container p {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.indicator-container .active {
  background-color: rgba(24, 52, 98, 0.2);
}

.indicator-container .inactive {
  background-color: #fff;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 20px;
  margin-right: 8px;
}

.indicator-container p i {
  top: -10px;
  position: absolute;
  font-size: 32px;
  color: #183462;
}

@media screen and (max-width: 1280px) {
  .indicator-container p {
    height: 60px;
  }
}
