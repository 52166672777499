.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
}

.col-lg-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.gap-3 {
  gap: 1rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}

.p-treenode-content.p-highlight {
  background: none;
  color: #495057;
}

.p-treenode-selectable:not(.p-highlight):hover {
  background: none;
}

.bg-primary {
  color: var(--primary-color-text) !important;
  background-color: #183462;
}

.border-round {
  border-radius: var(--border-radius) !important;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
.col {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.8rem;
}

.label-dados {
  font-weight: 750;
  font-size: small;
  padding-left: 0.5rem;
}

.panel-renda > .p-toggleable-content > .p-panel-content {
  background-color: #eff3f8;
}

.card-gasto {
  background: #fff;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1), 0 24px 36px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
}

.align-items-center {
  align-items: center !important;
}

.flex-score {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.border-round {
  border-radius: var(--border-radius) !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.border-right-1 {
  border-right-width: 1px !important;
  border-right-style: solid;
}
.p-3 {
  padding: 1rem !important;
}

.surface-border {
  border-color: var(--surface-border) !important;
}

.col-6,
.col-7 {
  flex: 0 0 auto;
  padding: 0.5rem;
}

.col-6 {
  width: 50%;
}

.text-center {
  text-align: center !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.text-color {
  color: #495057;
}

.text-success {
  color: #1cc88a !important;
}

.text-secondary {
  color: #858796 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.m-0 {
  margin: 0 !important;
}

.text-info {
  color: #36b9cc !important;
}

h4,
.h4 {
  font-size: 1.5rem;
}

.button_score:focus {
  border: none;
  box-shadow: none;
  background: transparent;
}

.line {
  width: 100%;
  height: 47px;
  border-bottom: 1px solid #183462;
}

.text-green-500 {
  color: #27ab83 !important;
}

.text-6xl {
  font-size: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.block {
  display: block !important;
}

.w-full {
  width: 100% !important;
}

.div-renda-print {
  display: none;
}

@media print {
  .div-renda-print {
    display: block;
  }
  .div-renda {
    display: none;
  }
  .print-text {
    font-size: 1.5rem;
  }
}

@media print {
  html,
  body {
    height: auto;
    font-size: 6pt; /* changing to 10pt has no impact */
  }
}

.col-12 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 100%;
}

.grid-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.border-none {
  border-width: 0 !important;
  border-style: none;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

@media screen and (min-width: 768px) {
  .md\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
}

@media print {
  .md\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.surface-border {
  border-color: var(--surface-border) !important;
}

@media screen and (min-width: 768px) {
  .md\:col-6,
  .md\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
}

@media screen and (min-width: 768px) {
  .md\:col-6 {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .lg\:col-3 {
    width: 25%;
  }
}

@media print {
  .lg\:col-3 {
    width: 25%;
  }
}

@media screen and (min-width: 992px) {
  .lg\:col-2,
  .lg\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
}

.bg-indigo-100 {
  background-color: var(--indigo-100) !important;
}

.text-indigo-700 {
  color: var(--indigo-700) !important;
}

.p-avatar.p-avatar-circle {
  border-radius: 50%;
}

.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}

.p-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.text-xl {
  font-size: 1.25rem !important;
}

.pi {
  font-family: primeicons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}

.text-indigo-700 {
  color: var(--indigo-700) !important;
}

.flex-score > .h-2rem {
  height: 2rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.bg-red-100 {
  background-color: var(--red-100) !important;
}

.text-red-900 {
  color: var(--red-900) !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.surface-200 {
  background-color: var(--surface-200) !important;
}

.h-full {
  height: 100% !important;
}

.bg-indigo-500 {
  background-color: var(--indigo-500) !important;
}

.text-green-700 {
  color: var(--green-700) !important;
}

.bg-green-100 {
  background-color: var(--green-100) !important;
}

.bg-green-500 {
  background-color: var(--green-500) !important;
}

.bg-yellow-500 {
  background-color: var(--yellow-500) !important;
}

.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}

.text-purple-700 {
  color: var(--purple-700) !important;
}

.bg-purple-100 {
  background-color: var(--purple-100) !important;
}

.text-yellow-700 {
  color: var(--yellow-700) !important;
}

.dot-gasto {
  height: 18px;
  width: 18px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot-gasto-purple {
  height: 18px;
  width: 18px;
  background-color: var(--purple-500);
  border-radius: 50%;
  display: inline-block;
}

.bg-purple-500 {
  background-color: var(--purple-500) !important;
}

.automotiva.p-divider.p-divider-horizontal {
  display: flow;
}

.ml-2 {
  margin-left: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.scrScore{
  width: 500px;
  height: 300px;
  overflow: hidden;
}

@media print {
  .scrScore{
      width: 650px;
      height: 300px;
      overflow: hidden;
  }
}
