.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.cardContent {
  width: 98%;
  height: 101px;
  display: flex;
  flex-direction: column;
  padding: 16px !important;
  background-color: #f2f2f2;
  border-radius: 4px;
  justify-content: space-between;
}
