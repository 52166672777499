.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.75rem;
   
}

.text-gray{
    color: hsl(232, 8%, 38%);
}

.align-items-center {
    align-items: center !important;
}

.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.d-sm-flex {
    display: flex !important;
}

.mb-0, .my-0 {
    margin-bottom: 0 !important;
}

.col-lg-12 {
    flex: 0 0 98%;
    max-width: 100%;
}

.shadow {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.cardHome {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e3e6f0;
    border-radius: 0.35rem;
}

.card-header:first-child {
    border-radius: calc(0.35rem - 1px) calc(0.35rem - 1px) 0 0;
}

.card-header {
    padding: 0.2rem 1.25rem;
    margin-bottom: 0;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0;
}

.text-primary {
    color: #07298c !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.h6 {
    font-size: 1rem;
}

.h4 {
    font-size: 1.5rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}