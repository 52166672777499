#interface-sidebar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: calc(100vh - 56px);
    width: 272px;
    margin-top: 56px;
    background-color: #f5f5f5;
    z-index: 100;
    overflow-x: hidden;
}
 
#interface-sidebar.closed {
    width: 70px;
}
 
#interface-sidebar menu {
    display: block;
    margin-block: 0;
    padding-inline: 0;
}
 
#interface-sidebar menu li {
    list-style: none;
    display: block;
}

#interface-sidebar menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 0px;
    max-height: 0;
    transition: max-height .45s cubic-bezier(.86,0,.07,1);
    overflow: hidden;
}
 
#interface-sidebar menu li a:hover, #interface-sidebar menu li a.active:hover  {
    background-color: #CFCED3;
    border-color: #CFCED3;
}
 
#interface-sidebar menu li a {
    display: flex;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    padding: 18px 0;
    text-decoration: none;
    color: #222;
    border-left: 4px solid #f5f5f5;
    align-items: center;
}
 
#interface-sidebar menu li a .icon {
    display: block;
    font-size: 20px;
    padding: 0 10px 0 18px;
}
 
#interface-sidebar menu li a.active {
    background-color: #fff;
    border-color: #264fec;
}

li.active-menuitem>ul {
    max-height: 1000px !important;
    transition: max-height 1s ease-in-out;
}

li.active-menuitem-open>ul>li>a {
   padding-left: 1rem !important;
}