.wrapper {
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 16px;
  font-family: "Roboto";
}

.label {
  color: #3f4555;
  font-weight: 500;
}

.value {
  color: #64748b;
  font-weight: normal;
}
