#interface-topbar {
  display: flex;
  position: fixed;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: #011e38;
  z-index: 101;
}

#interface-topbar .wrapper {
  display: flex;
  align-items: center;
  height: var(--gbpl-topbar-height);
}

.font-bold{
  font-weight: 650;
}

label.required:after{
  content: '*';
  color:red;
}

.wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.button_relatorio:focus {
  border: none;
  box-shadow: none;
  background: transparent;
}

.badge_relatorio {
  margin-top: -22px;
  margin-left: -6px;
}

.franquia {
  border-color: #fff;
  color: #fff;
  background: transparent;
}

.franquia span,
.franquia svg {
  border-color: #fff;
  color: #fff;
  background: transparent;
}

.franquia.p-focus {
  box-shadow: none;
  background: transparent;
}
