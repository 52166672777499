.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-container > *:nth-child(4n + 1),
.grid-container > *:nth-child(4n + 2) {
  background-color: #f0f0f0;
}

.grid-container > *:nth-child(4n + 3),
.grid-container > *:nth-child(4n + 4) {
  background-color: #ffffff;
}

.grid-item {
  display: flex;
  padding: 16px 16px;
  gap: 8px;
}

.grid-item label {
  font-weight: 600;
  color: #3f4555;
}

.grid-item p {
  color: #64748b;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
}
