.renajudTableContainer {
  padding: 1rem;
  max-width: 100%;
  overflow-x: auto;
}

.tribunalColumn {
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-word;
}

.p-datatable-striped tbody tr {
  padding: 10px 0;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  padding: 10px;
  text-align: left;
}

.messageWrapper {
  margin-bottom: 2rem;
}
