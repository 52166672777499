.horizontalStack {
  gap: 24px;
  display: flex;
  overflow-x: auto;
}

.imageContainer {
  width: 300px;
  display: flex;
  flex-shrink: 0;
  text-align: center;
  flex-direction: column;
}

.image {
  width: 100%;
  height: auto;
}
