.panel-header {
  width: 98%;
  position: relatives;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.score-content {
  width: 50%;
  height: 200px;
  overflow: hidden;
}

.score-container {
  display: flex;
  align-items: end;
  gap: 50px;
}

.score-info {
  width: 100%;
}

.listaWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.listaWrapper :nth-child(4n + 3),
.listaWrapper :nth-child(4n + 4) {
  background-color: #f0f0f0;
}
