.main-reset-password {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-reset-password {
  position: relative;
  display: flex;

  padding: 40px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #dee1eb;

  gap: 40px;

  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
}

.container-image-reset-password {
  flex: 1;
  display: flex;
  justify-content: center;
  width: 500px;
  background: #343434;
}

.container-image-reset-password img {
  width: 400px;
  object-fit: contain;
}

.container-form-reset-password {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.container-form-reset-password h2 {
  font-size: 1.5rem;
  padding: 0;
  margin-top: 0;
}

.input-container-reset-password {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
