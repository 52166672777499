.no-wrap-table .p-datatable-tbody > tr > td {
  white-space: nowrap;
}

.no-wrap-table .p-datatable-thead > tr > th {
  white-space: nowrap;
}

.no-wrap-table .p-datatable {
  table-layout: auto;
}

.no-wrap-table .p-datatable-wrapper {
  overflow-x: auto;
}
