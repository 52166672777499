.p-panel-header-green > .p-panel-header {
  background-color: #20eb1f;
}

.listaWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.listaWrapper > div:nth-child(4n + 3),
.listaWrapper > div:nth-child(4n + 4) {
  background-color: #f0f0f0;
}
