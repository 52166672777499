.gap-2 {
    gap: 0.5rem!important;
}

.flex-consulta {
    display: flex!important;
}

.justify-content-end {
    justify-content: flex-end!important;
}

.p-radiobutton.p-highlight .p-radiobutton-box{
    border-color: #183462;
    background: #183462;
}