.main-login {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-login {
  position: relative;
  display: flex;

  padding: 40px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #dee1eb;

  gap: 40px;

  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
}

.container-image-login {
  flex: 1;
  display: flex;
  justify-content: center;
  width: 500px;
  background: #343434;
}

.container-image-login img {
  width: 400px;
  object-fit: contain;
}

.container-form-login {
  display: flex;
  flex-direction: column;
}

.container-form-login h2 {
  font-size: 1.5rem;
  padding: 0;
  margin-top: 0;
}

.button-secondary {
  position: initial;
  width: 100%;
  height: 46px;

  font-weight: 600;
  color: #fff;
  background: #264fec;
  border-style: none;
  border-radius: 12px;

  margin: 32px 0;
  cursor: pointer;
}

.input-container-login {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-container-login label {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}

.reset-password {
  font-size: 1rem;
  color: #9a9a9a !important;
  text-decoration: none !important;

  margin-top: 8px;
}

.link-privacity {
  font-size: 1rem;
  color: #9a9a9af8 !important;
  text-decoration: none !important;

  margin: 0 auto;
}
