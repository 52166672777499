.btn-custom-solid {
  width: 100%;
  height: 46px;

  font-weight: 600;
  color: #fff;
  background: #264fec;
  border-style: none;
  border-radius: 12px;

  cursor: pointer;
}

.btn-custom-outlined {
  width: 100%;
  height: 46px;

  font-weight: 600;
  color: #264fec;
  background: transparent;
  border: 1px solid #264fec;
  border-radius: 12px;

  cursor: pointer;
}
