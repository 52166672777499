.wrapper {
  display: flex;
  flex-direction: column;
  width: 98%;
}

.header {
  display: flex;
  padding: 8px;
  border-radius: 8px;
  background-color: #20eb1f;
}

.title {
  margin: 0px;
  color: #3f4555;
  font-weight: 500;
}

.title:first-letter {
  text-transform: uppercase;
}

.listaWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dadosWrapper {
  display: flex;
  flex-direction: row;
}

.listaWrapper .dadosWrapper:nth-child(4n + 3),
.listaWrapper .dadosWrapper:nth-child(4n + 4) {
  background-color: f0f0f0;
}

.text {
  font-size: 16px;
  color: #3f4555;
  font-weight: normal;
  font-family: "Roboto";
}

.value {
  font-weight: 500;
}
.alertContainer {
  gap: 16px;
  display: grid;
  margin-bottom: 32px;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(6, 1fr);
}

.alertBox {
  height: 120px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ccc;
  transition: background-color 0.3s ease;
}

.gray {
  color: #333;
  background-color: #f0f0f0;
}

.red {
  color: #b22222;
  border: 2px solid #b22;
  background-color: #ffdddd;
}

.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}

.textAlert {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.headerContainer {
  display: flex;
  padding: 16px;
  color: #3f4555;
  border-radius: 8px;
  margin-bottom: 32px;
  align-items: center;
  background-color: #20eb1f;
  justify-content: space-between;
}

.headerItem {
  gap: 8px;
  display: flex;
  align-items: center;
}

.headerIcon {
  font-size: 1.5rem;
}

.label {
  margin: 0px;
  font-weight: bold;
}

.headerValue {
  font-size: 1rem;
}
