.alertContainer {
  display: flex;
  justify-content: center;
  width: 98%;
  margin-bottom: 2rem;
  gap: 16px;
}

.value {
  font-weight: 500;
}

.alertBox {
  width: 180px;
  height: 120px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ccc;
  transition: background-color 0.3s ease;
}

.gray {
  color: #333;
  background-color: #f0f0f0;
}

.red {
  color: #b22222;
  border: 2px solid #b22;
  background-color: #ffdddd;
}

.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}

.textAlert {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.headerContainer {
  display: flex;
  width: 98%;
  padding: 16px;
  color: #3f4555;
  border-radius: 8px;
  margin: 32px 0px;
  align-items: center;
  background-color: #20eb1f;
  gap: 24px;
}

.headerItem {
  gap: 8px;
  display: flex;
  align-items: center;
}

.headerIcon {
  font-size: 1.5rem;
}

.label {
  margin: 0px;
  font-weight: bold;
}

.headerValue {
  font-size: 1rem;
}

.imgContent {
  display: flex;
  border: 1px solid black;
  aling-items: center;
  justify-content: center;
}
