.formContainer {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formContainer > button {
  width: fit-content;
  margin-left: auto;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
